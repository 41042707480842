<template lang="pug">
  v-container(fluid)
    v-row
      v-toolbar(
        height="50" dark
      )
        v-toolbar-title
          v-img(
            :src="require('../assets/logo-mobiplus-branco.png')"
            width="80"
          )
        v-spacer
    v-row
      v-col(md="1" sm="0")
      v-col(cols="12" md="10" sm="12")
        v-card.rounded-lg.mt-4(elevation="2")
          v-card-title.py-2(v-if="selected.length === 0")
            v-btn(icon)
              v-icon.mr-2 attachment
            a( style="color: black" @click="enterFolder(-1)")
              | https://{{ host }}
            span(v-for="(i, index) in breadcrumbs")
              | /
              a(@click="enterFolder(index)") {{ i }}
            v-spacer
            v-btn.rounded-lg(
              color="blue darken-4" dark
              depressed dense small icon
              @click="activeFileInput"
            )
              v-icon(size="") cloud_upload

            input(
              type="file" @change="uploadFiles"
              ref="file" style="display: none"
              id="file-input" multiple
            )
            v-btn.ml-2(icon @click="dialogNewFolder = true" )
              v-icon folder
            v-btn(icon @click="refreshFiles")
              v-icon refresh

          // .v-car-title main
          v-card-title.cyan.py-2(v-else)
            v-btn(icon color="white" @click="selected = []" )
              v-icon close
            span.ml-3.mr-4(style="color: white; font-size: 12pt") {{ selected.length }} selecionados |
            v-btn.rounded-lg(
              color="white" small
            outlined depressed dense
            @click="dialogConfirmDelete = true"
            ) Excluir
            v-spacer
            v-btn.ml-3(
              @contextmenu="handler($event)"
              depressed small
              @click="dialogCopyFile = true"
            ) Copiar
            v-btn.ml-3(
              @contextmenu="handler($event)"
              depressed small
              @click="dialogMoveFile = true"
            ) Mover

          // .v-card-titel deleting
          v-divider

          v-card-text.pa-0
            v-text-field.px-3(v-model="search" label="Consultar" color="black")
            v-data-table(
              show-select
              v-model="selected"
              :items="items",
              :loading="loading"
              :headers="headers"
              item-key="name"
              :search="search"
              :footer-props="{'items-per-page-text': 'Resultados por página'}"
              :items-per-page="10"
              @click:row="selectItem"

            )
              template(v-slot:item.thumb_url="{ item }")
                v-img(
                  v-if="item.thumb_url !== ''"
                  :src="item.thumb_url"
                  width="50px"
                )
              template.pa-0(v-slot:progress)
                v-progress-linear.pa-0(color="cyan darken-2" background-color="cyan" indeterminate)
              template(v-slot:item.data-table-select="{ isSelected, select }")
                v-simple-checkbox(
                  color="cyan"
                  :value="isSelected"
                  @input="select($event)"
                )
              template(v-slot:item.name="{ item }")
                v-icon(v-if="item.type === 'pasta'" color="cyan") folder
                v-icon(v-else color="green" ) check_circle
                span.ml-2 {{ item.name }}
              template(v-slot:item.size="{item}")
                | {{ item.size }} MB
      v-col(md="1" sm="0")

    // COPIAR ARQUIVOS
    v-dialog(
      v-model="dialogCopyFile"
      max-width="400"
    )
      v-card(
        elevation="2"
      )
        v-card-text
          v-row
            v-col(md="12")
              v-text-field.mt-5(
                v-model="pathToMove"
                outlined dense
                color="cyan"
                placeholder="pasta/para/mover"
                label="Digite o caminho de destino"
              )
          v-btn.rounded-lg(
            :loading="loading"
            @click="copy()"
            depressed :color="loading ? 'grey' : 'cyan'" dark
          ) Mover
    // MOVER ARQUIVOS
    v-dialog(
      v-model="dialogMoveFile"
      max-width="400"
    )
      v-card(
        elevation="2"
      )
        v-card-text
          v-row
            v-col(md="12")
              v-text-field.mt-5(
                v-model="pathToMove"
                outlined dense
                color="cyan"
                placeholder="pasta/para/mover"
                label="Digite o caminho de destino"
              )
          v-btn.rounded-lg(
            :loading="loading"
            @click="move()"
            depressed :color="loading ? 'grey' : 'cyan'" dark
          ) Mover
    // ADICIONAR NOVA PASTA
    v-dialog(
      v-model="dialogNewFolder"
      max-width="400"
    )
      v-card(
        elevation="2"
      )
        v-card-text
          v-row
            v-col(md="12")
              v-text-field.mt-5(
                v-model="folder.name"
                outlined dense
                color="cyan"
                label="Nome da pasta"
              )
          v-btn.rounded-lg(
            :loading="loading"
            depressed :color="loading ? 'grey' : 'cyan'" dark @click="addFolder"
          ) Adicionar pasta
          v-btn.rounded-lg.ml-2(

            depressed @click="dialogNewFolder = false"
          ) Cancelar


    // STATUS DE UPLOAD
    v-dialog(
      v-model="progressUpload"
      max-width="400" persistent
    )
      v-card.rounded-lg(
        elevation="2"
      )
        v-card-title Progresso do upload
        v-card-text
          v-row(
            v-for="(file, index) in files"
            :key="index"
          )
            v-col(md="12")
              p {{ file.name }}
                v-icon.ml-3(v-if="file.progress === 100" size="18" color="green") check_circle
              v-progress-linear(
                :value="file.progress" height="5"
                :color="file.progress === 100 ? 'green' : 'cyan'"
              )
          v-btn.rounded-lg.mt-4(
            depressed color="red"

            dark @click="progressUpload = false"
          ) fechar

    // EXCLUIR ARQUIVOS
    v-dialog(
      v-model="dialogConfirmDelete"
      max-width="400"
    )
      v-card.rounded-lg(
        elevation="2"
      )
        v-card-text
          v-row
            v-col(md="12")
              v-alert.mt-5(
                dense
                outlined
                type="error"
                color="red accent-4"
              ) Tem certeza que deseja excluir os itens selecionados? Esta ação não poderá ser desfeita.
          v-btn.rounded-lg(
            :loading="loading"
            depressed :color="loading ? 'grey' : 'red'"
            dark @click="deleteItems"
          ) Excluir
          v-btn.rounded-lg.ml-2(depressed @click="dialogConfirmDelete = false") Cancelar

    // DEATALHES DO ARQUIVO
    v-dialog(
      v-model="dialog"
      max-width="400"
    )
      v-card.rounded-lg(
        elevation="2"
      )
        v-card-title {{ item.name }}
          v-spacer
          v-btn(icon @click="dialog = false")
            v-icon close

        v-card-text
          v-img(
            v-if="isImage(item.type) || hasThumbnail(item.thumb_url)"
            height="200px"
            lazy-src contain
            :src="hasThumbnail(item.thumb_url) ? item.thumb_url : item.url")
          v-list
            v-list-item.px-0
              v-list-item-content
                v-list-item-title  Nome do arquivo
                v-list-item-subtitle
                  a(:href="item.url" target="_blank") {{ item.name }}
                    v-icon.ml-1(size="small" color="blue darken-3") open_in_new
            v-list-item.px-0
              v-list-item-content
                v-list-item-title Tamanho do arquivo
                v-list-item-subtitle {{ item.size }} MB
            v-list-item.px-0
              v-list-item-content
                v-list-item-title Tipo do arquivo
                v-list-item-subtitle {{ item.type }}
            v-list-item.px-0
              v-list-item-content
                v-list-item-title Atualizado em
                v-list-item-subtitle {{ item.last_update }}
    v-snackbar(v-model="snackbar.value" top color="cyan"  dark)
      v-icon warning
      | {{ snackbar.text }}
</template>

<script>
import HomeService from "../services/HomeService";

export default {
  name: 'Home',
  components: {},
  data() {
    return {
      selected: [],
      item: {},
      search: '',
      host: '',
      dialog: false,
      dialogNewFolder: false,
      progressUpload: false,
      dialogMoveFile: false,
      dialogConfirmDelete: false,
      dialogCopyFile: false,
      headers: [
        {
          text: 'Nome',
          value: 'name',
        },
        {text: 'Thumbnail', value: 'thumb_url', width: '1px', sortable: false},
        {text: 'Tamanho', value: 'size'},
        {text: 'Tipo', value: 'type'},
        {text: 'Última modificação', value: 'last_update', align: 'end'},
      ],
      blockClick: false,
      pathToMove: '',
      items: [],
      loading: true,
      files: [],
      folder: {
        name: ''
      },
      breadcrumbs: [],
      snackbar: {
        value: false,
        text: ''
      }
    }
  },
  computed: {
    getPathCurrent() {
      this.pathToMove = this.breadcrumbs.join('/')
      return this.breadcrumbs.join('/');
    }
  },
  methods: {
    handler: function (e) {
      //do stuff
      e.preventDefault();
    },
    isImage(type) {
      let images = ['png', 'jpg', 'jpeg']
      return images.includes(type)
    },
    hasThumbnail(thumbnail) {
      return thumbnail !== ''
    },
    activeFileInput() {
      $("#file-input").trigger("click");
    },
    async move() {
      this.loading = true
      for (const index in this.selected) {
        let response = await HomeService.move({
          from: this.breadcrumbs.join('/') + '/' + this.selected[index].name,
          to: this.pathToMove
        })

        if (response.message !== undefined) {
          this.snackbar.value = true
          this.snackbar.text = response.message
          setTimeout(() => {
            this.snackbar.value = false
          }, 5000)
          break
        }
      }
      this.selected = []
      this.dialogMoveFile = false
      await this.refreshFiles()
    },
    async copy() {
      this.loading = true
      for (const index in this.selected) {
        await HomeService.copy({
          from: this.breadcrumbs.join('/') + '/' + this.selected[index].name,
          to: this.pathToMove
        })
      }
      this.selected = []
      this.dialogCopyFile = false
      await this.refreshFiles()
    },

    async deleteItems() {
      this.loading = true
      for (const index in this.selected) {
        await HomeService.deleteFiles(
            this.breadcrumbs.join('/') + '/' + this.selected[index].name
        )
      }

      await this.refreshFiles()
      this.dialogConfirmDelete = false
      this.selected = []
    },
    async addFolder() {
      this.loading = true
      await HomeService.createFolder({
        path: this.breadcrumbs.join('/') + '/' + this.folder.name
      })

      await this.refreshFiles()
      this.folder.name = ''
      this.dialogNewFolder = false
    },
    async uploadFiles(event) {
      console.log(event.target.files)
      let files = event.target.files

      this.progressUpload = true
      this.files = []

      for (let i = 0; i < files.length; i++) {
        console.log({file: files[i]})
        this.files.push({
          name: files[i].name,
          progress: 0
        })
        let formData = new FormData()

        formData.append('file', files[i])
        formData.append('path', this.breadcrumbs.join('/'))

        await HomeService.saveFile(formData, (event) => {
          let progress = Math.round(
              (event.loaded * 100) / event.total
          );
          this.files[i].progress = progress

          console.log(
              `A imagem está ${files[i].name} ${progress}% carregada... `
          );
        },)

      }
      await this.refreshFiles()

    },
    async refreshFiles() {
      this.loading = true
      this.items = await HomeService.listFromRoot(this.breadcrumbs.join('/'))
      this.loading = false

    },
    selectItem(item, body) {
      console.log({item, body})
      if (this.blockClick)
        return

      this.blockClick = true

      if (item.type !== 'pasta') {
        this.dialog = true

        this.item = item
        this.blockClick = false
      } else {
        this.loading = true
        this.breadcrumbs.push(item.name)
        HomeService.listFromRoot(this.breadcrumbs.join('/'))
            .then(_ => {
              console.log({_})
              this.items = _
              this.loading = false
              this.blockClick = false
            })
      }
    },
    enterFolder(index) {
      this.loading = true
      let path = this.breadcrumbs.slice(0, index + 1).join('/')


      console.log({BREAD_SIZE: this.breadcrumbs.length})
      HomeService.listFromRoot(path)
          .then(_ => {
            console.log({path: _})
            this.items = _
            this.loading = false
            if (index === -1)
              this.breadcrumbs = []
            else
              this.breadcrumbs = path.split('/')

          })
    }
  },
  mounted() {
    window.onpopstate = function (e) {
      console.log(e)
    };
    this.host = window.location.host

    HomeService.listFromRoot()
        .then(_ => {
          console.log({_})
          this.items = _
          this.loading = false
        })
  }
}
</script>

<style scoped>
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  padding: 0;
}
</style>