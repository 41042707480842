import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from "./plugins/vuetify";
import LocalStorage from "./services/generics/LocalStorage";

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  beforeMount() {
    if (LocalStorage.get('user') === null) {
      this.$router.push({name: 'Login'}).then()
    }
  },
  render: h => h(App)
}).$mount('#app')
