import http from './../plugins/axios'

export default class HomeService {

	static async listFromRoot(path = '') {
		let resp = await http.get(`/root/all?path=${path}`)
		return resp.data
	}

	static async saveFile(data, onUpload) {
		let resp = await http.post(`/root/files?no-thumb=0`, data, {
			onUploadProgress: onUpload
		})
		return resp.data
	}

	static async createFolder(data) {
		let resp = await http.post(`/root/directories`, data)
		return resp.data
	}

	static async deleteFiles(path) {
		let resp = await http.delete(`/root?path=${path}`)
		return resp.data
	}

	static async move ({from, to}) {
		let resp = await http.post(`/root/move`,{
			path: from,
			move_to: to
		})
		return resp.data
	}

	static async copy ({from, to}) {
		let resp = await http.post(`/root/copy`,{
			path: from,
			move_to: to
		})
		return resp.data
	}
}